import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { S3ImageUploadService } from 'app/shared/s3-image-upload.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ToastrService } from 'ngx-toastr';
import { DetailsPageService } from './details-page.service';

@Component({
  selector: 'app-details-page',
  templateUrl: './details-page.component.html',
  styleUrls: ['./details-page.component.scss'],
})
export class DetailsPageComponent implements OnInit {
  gridCols: number = 4;
  dynamicColspan: number = 4;
  selectedImageUrls: string[] = [];
  purchaseOrderId: number | null = null;
  newlyUploadedImages: string[] = [];
  


  invoiceForm: FormGroup;
  

  vehicleDetails = {
    make: '',
    model: '',
    orderCreated: null,
    status:'',
    registrationNumber:'',
    variantKey:'',
    year:''


  };

  tableData: Array<{ name: string; brand: string; qty: number }> = [];
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('pdfInput') pdfInput!: ElementRef;

  uploadedInvoiceName: string | null = null;
  uploadedInvoiceUrl: string | null = null;
  showUploadPopup = false;
  isInvoiceUploaded: boolean = false; 
  key: string;
  supplierStatus: string;
  fileData: File;
  images: string[] = []; // Initialize as an empty array.
  currentStatus: string='';
  invoiceNumber: any;
  image:any;
  invoicePath:any
  invoice1: any;
  imagePath: any;
  hideHamburgerMenu = false;
  loading: boolean;
  
 



  constructor(
    private router: Router,
    private detailsPageService: DetailsPageService,
    private s3ImageUploadService: S3ImageUploadService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private toastr: ToastrService, 

  ) {

    this.invoiceForm = this.fb.group({
      invoiceDate: new FormControl('', Validators.required),
      invoiceNumber: new FormControl('', Validators.required),
      // invoiceDate: new FormControl(''),
      // invoiceNumber: new FormControl(''), // Numbers only
      invoiceFile: new FormControl('', Validators.required),
    });
  }

  ngOnInit(): void {
    this.checkLogin();
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
    ]).subscribe(result => {
      if (result.matches) {
        if (result.breakpoints[Breakpoints.XSmall]) {
          this.gridCols = 1;
          this.dynamicColspan = 1; // Set colspan to 1 for smaller screens
        } else if (result.breakpoints[Breakpoints.Small]) {
          this.gridCols = 2;
          this.dynamicColspan = 2; // Adjust colspan for medium screens
        } else if (result.breakpoints[Breakpoints.Medium]) {
          this.gridCols = 3;
          this.dynamicColspan = 3; // Adjust as needed
        } else if (result.breakpoints[Breakpoints.Large]) {
          this.gridCols = 4;
          this.dynamicColspan = 4; // Default colspan for larger screens
        }
      }
    });
  
    this.route.paramMap.subscribe(params => {
      // console.log("get param: ",params);
      this.key = params.get('key');
    });
    
    this.fetchOrderDetails();

  }
 

  checkLogin(){
    let spareLoginToken = localStorage.getItem('vendorKey');
    if(!spareLoginToken){
      this.router.navigate(['login']);
    }
  }
  getStatusName(statusCode) {
    const statusMap = {
      0: 'CANCELLED',
      1: 'CREATED',
      2: 'CONFIRMED',
      3: 'READY TO PICK',
      4: 'DISPATCHED',
    };
    return statusMap[statusCode] || 'UNKNOWN';
  }

  fetchOrderDetails(): void {
    this.detailsPageService.getDetails(this.key).subscribe({
      next: (response: any) => {
        if (response.code === 200 && response.data) {
          const data = response.data;
          console.log("data--",data);
          // console.log("regno--",data.registrationNumber);
          this.purchaseOrderId = data.purchaseOrderId;
          this.invoicePath=data.retailerInvoicePath;
          this.imagePath=data.uploadImages;
          this.invoiceNumber=data.invoiceNumber;
          // console.log("data.supplierStatus",data.supplierStatus)
          this.vehicleDetails = {
            make: data.make,
            model: `${data.model}, ${data.fuelType}`,
            orderCreated: data.orderCreated,
            status:data.supplierStatus,
            registrationNumber:data.registrationNumber,
            variantKey:data.variant,
            year:data.manufactureYear
          };
          const pageHeaders = {
            title: 'Purchase Orders',
            values: this.purchaseOrderId
          };
        
          // Store in localStorage
          localStorage.setItem('pageHeaders', JSON.stringify(pageHeaders));

          this.tableData = data.spareItems.map((item: any) => ({
            name: item.name,
            brand: item.brand,
            qty: item.quantity,
          }));
          // this.router.events.subscribe(() => {
          //   this.hideHamburgerMenu = this.router.url.includes('order-details/:key');
          //   this.updateHamburgerMenuVisibility();
          // });
          (document.getElementsByClassName('toolbar-hamburger-menu')[0] as HTMLInputElement).innerHTML = '';
          
          (document.getElementsByClassName('toolbar-back-button')[0] as HTMLInputElement).innerHTML = 'arrow_back';
          
          (document.getElementsByClassName('toolbar-title')[0] as HTMLInputElement).innerHTML = 'Order Details';
          (document.getElementsByClassName('toolbar-sub-title')[0] as HTMLInputElement).innerHTML = this.purchaseOrderId.toString();
          
        } else {
          // console.error('Invalid API response', response);
        }
      },
      error: (err) => {
        // console.error('Error fetching order details:', err);
      },
    });
  }
  updateHamburgerMenuVisibility(): void {
    const hamburgerMenu = document.getElementsByClassName('toolbar-hamburger-menu')[0] as HTMLElement;
    if (hamburgerMenu) {
      hamburgerMenu.style.display = this.hideHamburgerMenu ? 'none' : 'block';
    }
  }

 // Open the hidden file input
openFileSelector(): void {
  this.fileInput.nativeElement.click();
}

 // Add this in your class to track newly uploaded images.

onFileSelected(event: Event): void {
  const input = event.target as HTMLInputElement;

  if (input.files && input.files.length > 0) {
    const files: FileList = input.files;
    this.newlyUploadedImages = []; // Reset the array for newly selected files.

    Array.from(files).forEach((file) => {
      // Get the pre-signed URL for each file.
      this.s3ImageUploadService.getPresignedUrl(file.name).subscribe({
        next: (response) => {
          const presignedUrl = response[0]?.signedUrl;
          const link = response[0]?.link;

          if (presignedUrl && link) {
            this.newlyUploadedImages.push(link); // Track the newly uploaded images.
            this.uploadFileToS3(presignedUrl, file);

            // Call updateImageList only after all files have been processed.
            if (this.newlyUploadedImages.length === files.length) {
              this.updateImageList(this.newlyUploadedImages);
            }
          } else {
            console.error('Invalid presigned URL or link:', response);
            this.loading = false; // Hide loading overlay.
          }
        },
        error: (err) => {
          console.error('Error fetching presigned URL:', err);
        },
      });

      // Optionally, show a preview of the selected images.
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const imageUrl = e.target?.result as string;
        this.selectedImageUrls.push(imageUrl);
      };
      reader.readAsDataURL(file);
    });
  } else {
    this.images = [];
    console.log('No files selected.');
  }
}


updateImageList(newImages: string[]): void {
  const updatedImages = [...this.images, ...newImages]; // Combine existing and newly uploaded images.

  this.detailsPageService.updateImage(this.key, updatedImages).subscribe({
    next: (response) => {
      this.images = updatedImages; // Update the main images array only after a successful API call.
      this.toastr.success('Images uploaded successfully');
      this.images = [];
      this.ngOnInit();
    },
    error: (err) => {
      console.error('Error calling updateImage API:', err);
    },
  });
}


removeImage(imageUrl): void {
  // Call the API to delete the image
  this.detailsPageService.deleteImage(this.key, imageUrl).subscribe({
    next: (response) => {
      // console.log('Image deleted successfully:', response);
      this.toastr.error('Images deleted successfully');
      // Remove the image from the selectedImageUrls and images arrays
      const index = this.imagePath.indexOf(imageUrl);
      if (index !== -1) {
        // Remove the matching string
        this.imagePath.splice(index, 1);
      }
      this.ngOnInit();
      // console.log('Updated selectedImageUrls:', this.selectedImageUrls);
      // console.log('Updated images list:', this.images);
    },
    error: (err) => {
      console.error('Error deleting image:', err);
    },
  });
}

onPdfSelected(event: Event): void {
  const input = event.target as HTMLInputElement;

  if (input.files && input.files.length > 0) {
    this.fileData = input.files[0];    
  }
}


uploadFileToS3(presignedUrl: string, file: File): void {
  console.log('Presigned URL:', presignedUrl);
    const contentType = file.type; 
    console.log("file---",file);
    console.log("contentType",contentType);
  this.http.put(presignedUrl,file,{
    headers: { 'Content-Type': contentType },
  }).subscribe({
    next: () => {
      // console.log('File uploaded successfully.');
      this.isInvoiceUploaded = true; 
      this.showUploadPopup = false; 
    },
    error: (err) => {
      console.error('Error uploading file:', err);
    },
    complete: () => {
      this.loading = false;
    },
  });
}
  openInvoice(invoicePath): void {
    if (invoicePath) {
      window.open(invoicePath, '_blank');
    }
  }
  openImage(imagePath: string): void {
    if (imagePath) {
      window.open(imagePath, '_blank');
    } else {
      console.error('Image path is invalid.');
    }
  }
  

  viewListPage(): void {
    this.router.navigate(['order-list']);
    
  }
  uploadInvoice(): void {
    if (this.invoiceForm.valid) {
      if (this.fileData) {
        this.s3ImageUploadService.getPresignedUrl(this.fileData.name).subscribe({
          next: (response) => {
            // console.log("response--", response);
            if (response && response[0]?.signedUrl && response[0]?.signedUrl.length > 0) {
              const presignedUrl = response[0]?.signedUrl;
              this.uploadFileToS3(presignedUrl, this.fileData);
              const vendorKey = '6318f6e4db3546642b034a12a139605f';
              const orderKey = this.key; 
              const invoiceNumber = this.invoiceForm.get('invoiceNumber')?.value;
              const invoicePath = response[0]?.link; 
              const invoiceDate = this.invoiceForm.get('invoiceDate')?.value;
              this.invoiceNumber=invoiceNumber;
              // Save the uploaded invoice details
          
              this.uploadedInvoiceName = this.fileData.name;
              this.uploadedInvoiceUrl = invoicePath;
  
              this.detailsPageService
                .updateInvoice(vendorKey, orderKey, invoiceNumber, invoicePath, invoiceDate)
                .subscribe({
                  next: (apiResponse) => {
                    // console.log("apiResponse", apiResponse);
                    // console.log('Invoice updated successfully:', apiResponse);
                    this.changeOrderStatus('CONFIRMED');
                    this.isInvoiceUploaded = true;
                    this.toastr.success('Invoice uploaded successfully');
                    this.showUploadPopup = false; 
                    this.ngOnInit();
                  },
                  error: (err) => {
                    console.error('Error updating invoice');

                  },
                });
            } else {
              console.error('Failed to get presigned URL');
            }
          },
          error: (err) => {
            console.error('Error fetching presigned URL');
          },
        });
      } else {
        console.error('No file selected for upload.');
      }
    } else {
      console.error('Form is invalid. Please check the fields.');
    }
  }
  
  changeOrderStatus(status: string): void {
    if (!this.key) {
      console.error('Order key is not available.');
      return;
    }
    this.detailsPageService.orderStatus(this.key, status).subscribe({
      next: (response: any) => {
        if (response && response.code === 200) {
          this.toastr.success('Status updated !!!');
          this.ngOnInit();
        } else {
          console.error('Failed to update order status');
        }
      },
      error: (err) => {
        console.error('Error updating order status:');
      },
    });
  }
  onUploadInvoice(): void {
    this.changeOrderStatus('CONFIRMED');
  }

  onMarkReadyToShip(): void {
    this.changeOrderStatus('READYTOPICK');
  }

  onMarkDispatched(): void {
    this.changeOrderStatus('DISPATCHED');
  }
  
  onDeleteInvoice(invoicePath): void {
    if (invoicePath && this.key) {
      this.detailsPageService.deleteInvoice(this.key, invoicePath).subscribe({
        next: (response: any) => {
          this.uploadedInvoiceName = null;
          invoicePath = null;
          this.isInvoiceUploaded = false;
          this.toastr.success('Invoice deleted successfully');
          this.ngOnInit();
        },
        error: (err) => {
          console.error('Error deleting invoice:', err);
        },
      });
    } else {

    }
  }
  

}




