let options: any;
if (window.location.host ==="www.vendor.autoversemobility.in") {
    options = {
        BASE_API_URL_VENDOR:"https://iapi.getpitstop.com/v1.0/autoverse/vendor",
        PRESIGNED_URL:"https://iapi.getpitstop.com/v1.0/shipment/V3"
        
    };
} else if (window.location.host.indexOf("www.test.vendor.autoversemobility.in") > -1) {
    options = {
        BASE_API_URL_VENDOR:"https://iapi.testpitstop.com/v1.0/autoverse/vendor",
        PRESIGNED_URL:"https://iapi.testpitstop.com/v1.0/shipment/V3"
    };
} else {
    options = {
        BASE_API_URL_VENDOR:"https://iapi.testpitstop.com/v1.0/autoverse/vendor",
        PRESIGNED_URL:"https://iapi.testpitstop.com/v1.0/shipment/V3"
    };
}
export const GlobalVariable = Object.freeze(options);