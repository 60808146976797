import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalVariable } from '../main/globals';


@Injectable({
  providedIn: 'root'
})
export class S3ImageUploadService {
  uploadInvoice(updatedFormData: any) {
    throw new Error('Method not implemented.');
  }

  constructor(private http:HttpClient) { }

  getPresignedUrl(fileName): Observable<any> {
    const headers = new HttpHeaders({
      'x-api-key': 'h6S5lcvELn2XLh8XURiFW1HSynljQywm6lbH1Yf7',
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    });
    const body = [fileName];
    console.log("bgghtht----",headers);
  
    return this.http.post(`${GlobalVariable.PRESIGNED_URL}`+"/presignedurl/create", body, { headers });

  }
}
